import React, { ReactNode, useState } from 'react';

import { Icon } from '@iwoca/orion';

import { Activation } from '../activation/Activation';
import { RequireAuthenticatedSeller } from '../auth/Auth';
import { IsSellerBlocked } from '../auth/IsSellerBlocked';
import { SellerNavBar } from '../components/SellerNavBar/SellerNavBar';
import iwocaPayLogo from '../components/svg/iwocaPayLogo.svg';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { PayLinksNavigation } from '../Seller/PayLinks/PayLinksNavigation';
import { SellerXeroContextProvider } from '../Seller/Xero/Xero.context';

const SellerPageTemplate = ({ children }: { children: ReactNode }) => {
  const [isSideBarVisible, setIsSideBarVisible] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:1024px)');

  const MobileHeader = () => {
    return (
      <div className="grid grid-cols-[min-content,1fr] p-l">
        <img src={iwocaPayLogo} className="h-[24px]" alt="iwocaPay logo" />
        <div className="justify-self-end">
          <button
            className="flex w-fit border-none bg-transparent text-s font-med"
            onClick={() => setIsSideBarVisible(!isSideBarVisible)}
          >
            Menu
            <Icon
              icon={!isSideBarVisible ? 'hamburgerMenu' : 'timesDefault'}
              className="h-[20px] w-[20px] pl-s"
            />
          </button>
        </div>
      </div>
    );
  };

  const MainContent = () => {
    return (
      <div className="mx-auto w-full max-w-[1440px] px-2xl m:px-m">
        {children}
      </div>
    );
  };

  const NavigationBar = () => {
    return (
      <header className="border-r-[1px] border-r-secondary-90 bg-secondary-95">
        <SellerNavBar />
      </header>
    );
  };

  return (
    <div>
      {isMobile && (
        <div className="grid grid-cols-1">
          <MobileHeader />
          {isSideBarVisible ? <NavigationBar /> : <MainContent />}
        </div>
      )}
      {!isMobile && (
        <div className="grid grid-cols-[200px_1fr]">
          <NavigationBar />
          <MainContent />
        </div>
      )}
    </div>
  );
};

export const SellerInnerPageTemplate = ({
  title,
  additionalContent,
  children,
}: {
  title?: string;
  additionalContent?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="mb-l">
      <div className="mt-l flex w-full items-center">
        {title && <h1 className="m-0 mb-xl mt-xl text-4xl">{title}</h1>}
        {additionalContent && <>{additionalContent}</>}
      </div>
      {children}
    </div>
  );
};

export const NonAuthenticatedSellerOuterTemplate = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <SellerPageTemplate>{children}</SellerPageTemplate>;
};

export const AuthenticatedSellerOuterTemplate = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <RequireAuthenticatedSeller>
      <Activation>
        <SellerPageTemplate>
          <IsSellerBlocked>
            <SellerXeroContextProvider>{children}</SellerXeroContextProvider>
          </IsSellerBlocked>
        </SellerPageTemplate>
      </Activation>
    </RequireAuthenticatedSeller>
  );
};

export const SellerPayLinksTemplate = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <>
      <PayLinksNavigation />
      {children}
    </>
  );
};
