import { createContext, useContext, useState } from 'react';

import { tracking } from '@iwoca/frontend-tracking-library';
import {
  fetchPostApplicationDecline,
  fetchPostFormalOffers,
} from '@iwoca/lapi-client/edge';
import { maxBy } from 'lodash-es';
import { useNavigate } from 'react-router-dom';

import {
  DecisionErrorTypes,
  getApprovalRequirementErrors,
  selectApprovalRequirementErrorWithHighestPriority,
} from './ApprovalRequirements';
import { DECISION_MODAL_ID } from './components/DecisionModal/DecisionModal';
import { redirectToAccountPages } from './useRedirectToAccountPages';
import {
  useGetAccounts,
  useGetProductOffers,
  useGetBuyerSpendingLimitRequest,
} from '../../api/lending/lapiHooks';
import { getIwocaPayFormalOffers } from '../../Buyer/Checkout/helpers/formalOffer';
import { useStateKey } from '../../hooks/useStateKey.hook';
import logger from '../../utils/logger';
import { wait } from '../../utils/Wait';
import { useCurrentPayLink } from '../Checkout/hooks/useCurrentPayLink';
import { useModal } from '../store/ModalProvider';
import { markSignupAsCompleted } from '../utils/customerState';
import { getSuggestedProduct, SuggestedProductType } from '../utils/product';
import { buildQueryString } from '../utils/queryParams';

export enum DecisionStatus {
  IDLE = 'idle',
  SUBMITTING = 'submitting',
  CHECK_CREDIT_PROFILE = 'check_credit_profile',
  APPROVED = 'approved',
  DECLINED = 'declined',
  UNKNOWN = 'unknown',
  ERROR = 'error',
}

const DecisionContext = createContext<{
  decisionStatus: DecisionStatus;
  requestDecision: () => Promise<void>;
  decisionError: string | null;
}>({
  decisionStatus: DecisionStatus.IDLE,
  requestDecision: async () => {},
  decisionError: null,
});

export const DecisionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { openModal, closeModal } = useModal(DECISION_MODAL_ID);
  const { stateKey } = useStateKey();
  const { payLink } = useCurrentPayLink();
  const { spendingLimitRequest, loadingSpendingLimitRequest } =
    useGetBuyerSpendingLimitRequest();
  const { fetchProductOffers } = useGetProductOffers({ enabled: false });
  const { fetchAccounts } = useGetAccounts({ enabled: false });
  const [decisionState, setDecisionState] = useState<{
    status: DecisionStatus;
    error: string | null;
  }>({
    status: DecisionStatus.IDLE,
    error: null,
  });
  const navigate = useNavigate();
  const setDecisionStatus = (status: DecisionStatus) =>
    setDecisionState({ status, error: null });
  const decisionStatus = decisionState.status;

  const setDecisionError = (decisionError: string) =>
    setDecisionState({
      status: DecisionStatus.ERROR,
      error: decisionError,
    });
  const decisionError = decisionState.error;

  const requestDecision = async () => {
    setDecisionStatus(DecisionStatus.SUBMITTING);

    openModal();

    try {
      if (!stateKey) {
        throw new Error('State Key is missing');
      }
      if (loadingSpendingLimitRequest) {
        return;
      }

      if (!payLink && !spendingLimitRequest?.data?.uuid) {
        throw new Error(
          'Both Pay Link Id and Spending Limit Request are missing',
        );
      }

      await wait(500); // Giving them the feeling something is happening

      setDecisionStatus(DecisionStatus.CHECK_CREDIT_PROFILE);

      const { data: productOffers } = await fetchProductOffers();
      const suggestedProduct = getSuggestedProduct(productOffers!);

      if (!suggestedProduct) {
        logger.error('Missing suggested product', {
          stateKey: stateKey,
        });
        throw new Error('Missing suggested product');
      }

      const approvalRequirementErrors =
        getApprovalRequirementErrors(suggestedProduct);
      if (Array.from(approvalRequirementErrors.values()).length) {
        const highestPriorityError =
          selectApprovalRequirementErrorWithHighestPriority(
            approvalRequirementErrors,
          );
        throw new Error(highestPriorityError);
      }

      const isDeclined = checkStatusIsDeclined(suggestedProduct);
      if (isDeclined) {
        await handleDecline({ suggestedProduct, stateKey });
        return;
      }

      const isApproved = checkHasOffer(suggestedProduct);
      if (isApproved) {
        tracking.ampli.decisionModalViewed({
          decision_modal_state: 'approved',
        });
        // 1. Formalise both offers
        await formaliseBothOffers({
          stateKey,
          offers: suggestedProduct.offers,
        });
        await markSignupAsCompleted({ stateKey });

        // 2. If spending limit, send to unique approved page
        if (spendingLimitRequest?.data?.uuid) {
          await redirectToSpendingLimitApprovedPage();
          return;
        }

        // 3. Handle partial approval
        const { data: accounts } = await fetchAccounts();
        const iwocaPayFormalOffers = getIwocaPayFormalOffers(accounts!);

        const isPartialApproval =
          iwocaPayFormalOffers?.filter((offer) => {
            if (!offer.available_cash) return false;
            return offer.available_cash >= payLink!.amount;
          }).length == 0;

        // 4. If partial, send to pandora
        if (isPartialApproval) {
          redirectToAccountPages();
          return;
        }

        // Base case: handle approved
        await handleMultiOfferApproved();
        return;
      }

      await handleUnknown();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logger.error('Failed to make decision', {
        stateKey: stateKey,
        error: error.message,
      });
      if (
        error.message === DecisionErrorTypes.DIRECTORS_COMPANY_MISMATCH_ERROR
      ) {
        tracking.ampli.decisionModalViewed({
          decision_modal_state: 'director_mismatch',
        });
      } else if (
        error.message === DecisionErrorTypes.INCOMPLETE_CREDIT_CHECK_ERROR
      ) {
        tracking.ampli.decisionModalViewed({
          decision_modal_state: 'incomplete_credit_check',
        });
      } else {
        tracking.ampli.decisionModalViewed({
          decision_modal_state: 'error',
        });
      }
      setDecisionError(error.message);
    }
  };

  const handleDecline = async ({
    suggestedProduct,
    stateKey,
  }: {
    suggestedProduct: NonNullable<SuggestedProductType>;
    stateKey: string;
  }) => {
    setDecisionStatus(DecisionStatus.DECLINED);
    const productDeclineUid = getProductDeclineUid(suggestedProduct);

    await declineProduct({
      stateKey: stateKey!,
      productTypeDeclineUid: productDeclineUid!,
      status: suggestedProduct.status!,
    });

    await markSignupAsCompleted({ stateKey });
    tracking.ampli.decisionModalViewed({ decision_modal_state: 'declined' });

    await wait(1750);

    redirectToAccountPages();
  };

  const handleUnknown = () => {
    tracking.ampli.decisionModalViewed({
      decision_modal_state: 'unknown',
    });
    setDecisionStatus(DecisionStatus.UNKNOWN);
  };

  const redirectToSpendingLimitApprovedPage = async () => {
    setDecisionStatus(DecisionStatus.APPROVED);

    await wait(500);

    const queryString = buildQueryString();
    navigate(`/pay/spending-account/approved/${queryString}`);

    closeModal();
  };

  const handleMultiOfferApproved = async () => {
    setDecisionStatus(DecisionStatus.APPROVED);

    await wait(500);

    const queryString = buildQueryString();
    navigate(`/pay/choose-product/${queryString}`);

    closeModal();
  };

  return (
    <DecisionContext.Provider
      value={{ requestDecision, decisionStatus, decisionError }}
    >
      {children}
    </DecisionContext.Provider>
  );
};

export function useDecision() {
  const { requestDecision, decisionStatus, decisionError } =
    useContext(DecisionContext);

  return { requestDecision, decisionStatus, decisionError };
}

function getOffers(suggestedProduct: SuggestedProductType) {
  return suggestedProduct?.offers;
}

type OffersType = ReturnType<typeof getOffers>;

export function checkStatusIsDeclined(product: SuggestedProductType) {
  if (!product?.status) return false;
  return ['hard_declined', 'soft_declined', 'automatically_rejected'].includes(
    product.status,
  );
}

export function checkHasOffer(product: SuggestedProductType) {
  return getNumberOfOffers(product) > 0;
}

export function getNumberOfOffers(product: SuggestedProductType) {
  return product?.offers?.length || 0;
}

export function getProductDeclineUid(product: SuggestedProductType) {
  return product?.product_type_decline?.uuid;
}

export function formaliseHighestOffer({
  stateKey,
  offers,
  threeMonthsOnly = false,
}: {
  stateKey: string;
  offers: NonNullable<OffersType>;
  threeMonthsOnly?: boolean;
}) {
  const allowedOffers = threeMonthsOnly
    ? offers.filter((offer) => [91, 3].includes(offer.duration?.amount))
    : offers;

  const offer =
    allowedOffers.length === 1
      ? allowedOffers[0]
      : maxBy(allowedOffers, (offer) => offer.max_amount)!;

  return fetchPostFormalOffers({
    stateKey,
    body: { data: { offer_ids: [offer.id] } },
  });
}

function formaliseBothOffers({
  stateKey,
  offers,
}: {
  stateKey: string;
  offers: NonNullable<OffersType>;
}) {
  return fetchPostFormalOffers({
    stateKey,
    body: { data: { offer_ids: offers.map((offer) => offer.id) } },
  });
}

export function declineProduct({
  stateKey,
  status,
  productTypeDeclineUid,
}: {
  stateKey: string;
  status: string;
  productTypeDeclineUid: string;
}) {
  const declineStatus =
    status === 'hard_declined' ? 'Hard Declined' : 'Soft Declined';

  return fetchPostApplicationDecline({
    stateKey: stateKey,
    body: {
      data: {
        status: declineStatus,
        product_type_decline_uid: productTypeDeclineUid,
      },
    },
  });
}
