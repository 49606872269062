import { createContext, ReactNode, useContext, useState } from 'react';

import { GetXeroConnectionsResponse } from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';
import { flushSync } from 'react-dom';

import { GetXeroThemesAPIResponse } from './InvoiceBrandingTheme/InvoiceBrandingTheme.types';
import {
  XeroAccount,
  XeroAccountResponse,
  SellerContextValues,
} from './Xero.types';
import { ActivationContext } from '../../activation/Activation';
import { fetchGetXeroConnections } from '../../api/lending/edge';
import { LapiError } from '../../api/lending/LapiError';
import { useStateKey } from '../../hooks/useStateKey.hook';
import { lendingApiFetchJson } from '../../Pages/lendingApiFetch';

const initialState: SellerContextValues = {
  loading: true,
  refreshing: false,
  error: false,
  stateKey: null,
};

export const SellerXeroContext = createContext<
  SellerContextValues & {
    refresh: () => void;
  }
>({
  ...initialState,
  refresh: () => {},
});

export function SellerXeroContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [refreshing, setRefreshing] = useState(false);
  const { isOnboarded, isLoading: isLoadingContext } =
    useContext(ActivationContext);
  const { stateKey } = useStateKey();

  const canFetchXero = !!isOnboarded && !!stateKey;

  const {
    data: xeroAccount,
    isLoading: loadingXeroAccount,
    refetch: fetchXeroAccount,
  } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerAccount'],
    queryFn: () => fetchGetXeroIwocapaySellerAccount(stateKey!),
    enabled: canFetchXero,
  });

  const {
    data: xeroThemes,
    isLoading: loadingXeroThemes,
    refetch: fetchXeroThemes,
  } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerBrandingThemes'],
    queryFn: () => fetchGetXeroIwocapaySellerBrandingThemes(stateKey!),
    enabled: canFetchXero,
  });

  const {
    data: xeroConnections,
    isLoading: loadingXeroConnections,
    refetch: fetchXeroConnections,
  } = useQuery({
    queryKey: ['fetchGetXeroConnectionsIwocapaySeller'],
    queryFn: () => fetchGetXeroConnections(stateKey!),
    enabled: canFetchXero,
  });

  const isLoading =
    isLoadingContext ||
    loadingXeroAccount ||
    loadingXeroThemes ||
    loadingXeroConnections;

  const organisationName = getLinkedOrganisationName(
    xeroConnections?.data.connections,
    xeroAccount?.data.seller_account,
  );

  const refresh = async () => {
    if (refreshing === true) return;
    if (!canFetchXero) return;

    flushSync(() => {
      setRefreshing(true);
    });

    await Promise.all([
      fetchXeroConnections(),
      fetchXeroAccount(),
      fetchXeroThemes(),
    ]);

    flushSync(() => {
      setRefreshing(false);
    });
  };

  return (
    <SellerXeroContext.Provider
      value={{
        refreshing: refreshing,
        stateKey: stateKey,
        loading: isLoading,
        error: false,
        xeroThemes: xeroThemes?.data.branding_themes,
        xeroAccount: xeroAccount?.data.seller_account,
        xeroConnections: isOnboarded ? xeroConnections?.data.connections : [],
        organisationName: organisationName,
        refresh,
      }}
    >
      {children}
    </SellerXeroContext.Provider>
  );
}

function getLinkedOrganisationName(
  connections?: GetXeroConnectionsResponse['data']['connections'],
  account?: XeroAccount,
) {
  const connection = connections?.find(
    (connection) => connection.tenant_id === account?.tenant.tenant_id,
  );

  return connection?.tenant_name;
}

async function fetchGetXeroIwocapaySellerAccount(
  stateKey: string,
): Promise<XeroAccountResponse | undefined> {
  const res = await fetch(
    `/api/lending/edge/xero/iwocapay_seller/account/${stateKey}/`,
    { credentials: 'same-origin' },
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}

async function fetchGetXeroIwocapaySellerBrandingThemes(
  stateKey: string,
): Promise<GetXeroThemesAPIResponse | undefined> {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/xero/iwocapay_seller/branding_themes/${stateKey}/`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}
